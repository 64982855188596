import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { RefreshTokenInterceptor } from './refresh-token.interceptor';

/** Http interceptor providers. */
export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
];
