import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { first, map, Observable, switchMap } from 'rxjs';

import { AppConfigService } from '../services/app-config.service';
import { UserSecret, UserSecretStorageService } from '../services/user-secret-storage.service';

const NO_INTERCEPT_URL_PATHS = [
	'/api/v1/auth/login/',
	'/api/v1/auth/password-reset/',
	'/api/v1/auth/password-reset-confirm/',
];

const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX = 'Token';

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private readonly appConfigService = inject(AppConfigService);

	private readonly userSecretStorage = inject(UserSecretStorageService);

	/** @inheritdoc */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.shouldInterceptToken(req.url)) {
			const userSecret$ = this.userSecretStorage.currentSecret$.pipe(first());

			return userSecret$.pipe(
				map(userSecret =>
					userSecret ?
						req.clone({
							headers: this.appendAuthorizationHeader(
								req.headers,
								userSecret,
							),
						}) :
						req),
				switchMap(newReq => next.handle(newReq)),
			);
		}

		// Do nothing.
		return next.handle(req);
	}

	/**
	 * Checks if a request is for authorization or refresh token.
	 * @param url - Request url.
	 */
	private shouldInterceptToken(url: string): boolean {
		return (
			url.startsWith(this.appConfigService.apiUrl) &&
			!NO_INTERCEPT_URL_PATHS.includes(new URL(url).pathname)
		);
	}

	/**
	 * Appends authorization header to a list of `headers`.
	 * @param headers Headers list.
	 * @param userSecret User secret.
	 */
	private appendAuthorizationHeader(
		headers: HttpHeaders,
		userSecret: UserSecret,
	): HttpHeaders {
		return headers.set(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${userSecret.value}`);
	}
}
