import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { IconsService } from '@asecrmc/common/core/services/icons.service';

/** Base app component. */
@Component({
	selector: 'asecrmw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet],
})
export class AppComponent {

	public constructor() {
		const iconService = inject(IconsService);

		iconService.registerIcons();
	}
}
