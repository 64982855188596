import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

import { MAX_DATE } from './constants';
import { isInvalidDate } from './datetime-utils';

/** Custom date adapter. Transforms date to M/d/yy format. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
	/** @inheritdoc */
	public override format(date: Date): string {
		if (date > MAX_DATE) {
			return date.toLocaleDateString();
		}

		if (!isInvalidDate(date)) {
			const year = this.getYearValue(date);
			const month = (date.getMonth() + 1).toString();
			const day = date.getDate().toString();

			return [month, day, year].join('/');
		}

		return '';
	}

	private getYearValue(date: Date): string {
		const year = date.getFullYear();
		if (year < 2100 && year > 1900) {
			return year.toString().slice(-2);
		}

		return year.toString();
	}
}
