import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpStatusCode, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { CurrentUserService } from '../services/current-user.service';

/** Interceptor to add access token to requests. */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	/** Request to refresh token. */
	private refreshTokenRequest$: Observable<void> | null = null;

	private readonly currentUserService = inject(CurrentUserService);

	/** @inheritdoc */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req)
			.pipe(
				catchHttpErrorResponse(error => {
					if (this.shouldHttpErrorBeIgnored(error)) {
						return throwError(() => error);
					}

					this.refreshTokenRequest$ ??= this.currentUserService.refreshSecret();

					return this.refreshTokenRequest$.pipe(
						tap(() => {
							this.refreshTokenRequest$ = null;
						}),
						switchMap(() => next.handle(req)),
					);
				}),
			);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		return error.status !== HttpStatusCode.Unauthorized;
	}
}
