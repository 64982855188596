import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { CurrentUserService } from '@asecrmc/common/core/services/current-user.service';
import { routePaths } from '@asecrmc/common/core/utils/route-paths';

/** Guard prevents user from accessing Auth module if a user is already logged in. */
export function unauthorizedGuard(): Observable<boolean | UrlTree> {
	const currentUserService = inject(CurrentUserService);

	const router = inject(Router);

	return currentUserService.isAuthenticated$.pipe(
		first(),
		map(isAuthenticated => !isAuthenticated || router.createUrlTree(routePaths.home)),
	);
}
