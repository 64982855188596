import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';

import { routePaths } from '@asecrmc/common/core/utils/route-paths';

import { NavListComponent } from '../nav-list/nav-list.component';

/** App toolbar component. */
@Component({
	selector: 'asecrmw-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatToolbar, RouterLink, MatIconButton, MatIcon, NavListComponent],
})
export class ToolbarComponent {

	/** Emits when navigation menu is clicked. */
	@Output()
	public readonly navigationMenuClicked = new EventEmitter<void>();

	/** @see {@link routePaths}. */
	protected readonly routePaths = routePaths;

	/** Handles navigation menu click. */
	protected onNavigationMenuClick(): void {
		this.navigationMenuClicked.emit();
	}
}
