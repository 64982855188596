import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Footer component. */
@Component({
	selector: 'asecrmw-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class FooterComponent {

	/** Current year. */
	protected readonly currentYear = new Date().getFullYear();
}
