import { enableProdMode, Provider, APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { browserTracingIntegration, init, TraceService, createErrorHandler } from '@sentry/angular';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, Router } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';

import { httpInterceptorProviders } from '@asecrmc/common/core/interceptors';

import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { angularMaterialProviders } from './angular-material-providers';

const sentryProviders: Provider[] = [
	{
		provide: TraceService,
		deps: [Router],
	},
	{
		provide: APP_INITIALIZER,
		useFactory: () => () => undefined,
		deps: [TraceService],
		multi: true,
	},
	{
		provide: ErrorHandler,
		useValue: createErrorHandler({
			showDialog: false,
		}),
	},
];

const isProduction = import.meta.env.NG_APP_PRODUCTION.toLowerCase() === 'true';

/** Checks whether the app works in prod environment. */
function isProdEnvironment(): boolean {
	return isProduction;
}

if (isProdEnvironment()) {
	enableProdMode();
}

init({
	dsn: import.meta.env.NG_APP_SENTRY_DSN,
	integrations: [browserTracingIntegration()],
	tracePropagationTargets: [import.meta.env.NG_APP_API_URL],
	environment: isProduction ? 'production' : 'development',
	enabled: !isProduction,
	tracesSampleRate: 1.0,
});

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule),
		httpInterceptorProviders,
		provideNgxMask(),
		...angularMaterialProviders,
		...sentryProviders,
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(appRoutes),
	],
})
	.catch(err => console.error(err));
