import { Component, ChangeDetectionStrategy, OnInit, inject, DestroyRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FooterComponent } from '../footer/footer.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { NavListComponent } from '../nav-list/nav-list.component';

/** App layout component.  */
@Component({
	selector: 'asecrmw-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ToolbarComponent,
		CdkScrollable,
		BreadcrumbsComponent,
		RouterOutlet,
		FooterComponent,
		MatSidenavModule,
		MatListModule,
		MatIconModule,
		NavListComponent,
		AsyncPipe,
	],
})
export class LayoutComponent implements OnInit {
	private readonly breakpointObserver = inject(BreakpointObserver);

	private readonly destroyRef = inject(DestroyRef);

	/** Is navigation menu open. */
	protected readonly isNavigationMenuOpen$ = new BehaviorSubject<boolean>(false);

	/** @implements */
	public ngOnInit(): void {

		// Closes the navigation menu when switching to desktop format.
		// Please note that the transition value must match what is specified in the styles (toolbar.component.css).
		this.breakpointObserver
			.observe(['(max-width: 1400px)'])
			.pipe(
				tap(() => {
					this.isNavigationMenuOpen$.next(false);
				}),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();
	}

	/** Handles navigation menu clicks. */
	protected onNavigationMenuClick(): void {
		this.isNavigationMenuOpen$.next(!this.isNavigationMenuOpen$.value);
	}

	/** Handles navigation list clicks. */
	protected onNavigationListClick(): void {
		this.isNavigationMenuOpen$.next(false);
	}
}
