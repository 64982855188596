import { Provider } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

import { CustomDateAdapter } from '@asecrmc/common/core/utils/custom-date-adapter';

/** Angular material providers. */
export const angularMaterialProviders: readonly Provider[] = [
	{
		provide: DateAdapter,
		useClass: CustomDateAdapter,
	},
	{ provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
	{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
	{ provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true } },
	{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
	{ provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
];
