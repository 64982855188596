<mat-toolbar
	color="primary"
	class="header"
>
	<button
		class="navigation-menu-button"
		mat-icon-button
		aria-label="Navigation menu"
		type="button"
		(click)="onNavigationMenuClick()"
	>
		<mat-icon>menu</mat-icon>
	</button>

	<a
		class="logo"
		[routerLink]="routePaths.home"
	>
		<img
			class="logo__image"
			src="assets/images/logo-white.png"
			alt="AllStar Elevator"
		/>
	</a>

	<asecrmw-nav-list
		class="navigation-menu"
		listDirection="row"
		buttonStyles="primary"
	/>
</mat-toolbar>
