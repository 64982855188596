import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { first } from 'rxjs';

import { AuthService } from '@asecrmc/common/core/services/auth.service';
import { routePaths } from '@asecrmc/common/core/utils/route-paths';

const navigationMenuItems = [
	{
		name: 'Dashboard',
		route: routePaths.dashboard,
	},
	{
		name: 'Customers',
		route: routePaths.customers,
	},
	{
		name: 'Devices',
		route: routePaths.devices,
	},
	{
		name: 'Buildings',
		route: routePaths.buildings,
	},
	{
		name: 'Proposals',
		route: routePaths.proposals,
	},
	{
		name: 'Invoices',
		route: routePaths.invoices,
	},
	{
		name: 'Inspections',
		route: routePaths.inspections,
	},
	{
		name: 'Periodic',
		route: routePaths.periodic,
	},
	{
		name: 'Schedules',
		route: routePaths.schedules,
	},
	{
		name: 'Dob Now',
		route: routePaths.dobNow,
	},
	{
		name: 'Contacts',
		route: routePaths.contacts,
	},
];

/** Nav list component. */
@Component({
	selector: 'asecrmw-nav-list',
	templateUrl: 'nav-list.component.html',
	styleUrl: 'nav-list.component.css',
	standalone: true,
	imports: [
		RouterLink,
		MatAnchor,
		RouterLinkActive,
		MatIconButton,
		MatIcon,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListComponent {

	/** List direction.  */
	@Input()
	public listDirection: 'row' | 'column' = 'row';

	/** Button styles.  */
	@Input()
	public buttonStyles: 'primary' | 'secondary' = 'primary';

	/** Emits when navigation menu is clicked.  */
	@Output()
	public readonly menuClick = new EventEmitter<void>();

	/** @see {@link routePaths}. */
	protected readonly routePaths = routePaths;

	/** Navigation menu items. */
	protected readonly navigationMenuItems = navigationMenuItems;

	private readonly authService = inject(AuthService);

	/** Logout. */
	protected logout(): void {
		this.authService.logout()
			.pipe(first())
			.subscribe();
	}
}
