<nav
	class="nav list"
	[class.list_column]="listDirection === 'column'"
>
	@for (item of navigationMenuItems; track item.route) {
		<a
			class="list__item list-item"
			[class.list__item_primary]="buttonStyles === 'primary'"
			[class.list__item_secondary]="buttonStyles === 'secondary'"
			mat-button
			routerLinkActive="active"
			[routerLink]="item.route"
			(click)="menuClick.emit()"
		>
			{{ item.name }}
		</a>
	}

	<button
		mat-icon-button
		[class.button_primary]="buttonStyles === 'primary'"
		[class.button_secondary]="buttonStyles === 'secondary'"
		type="button"
		(click)="logout()"
	>
		<mat-icon svgIcon="logout" />
	</button>
</nav>
