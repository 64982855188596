<xng-breadcrumb separator="→">
	<ng-container *xngBreadcrumbItem="let breadcrumb">
		<span
			class="breadcrumb"
			[title]="breadcrumb"
		>
			{{ breadcrumb | uppercase }}
		</span>
	</ng-container>
</xng-breadcrumb>
