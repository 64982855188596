<asecrmw-toolbar (navigationMenuClicked)="onNavigationMenuClick()" />

@let isNavigationMenuOpen = isNavigationMenuOpen$ | async;
<mat-sidenav-container
	autosize
	class="page-scroll"
	(backdropClick)="onNavigationMenuClick()"
>
	<mat-sidenav
		class="navigation-sidenav"
		[opened]="isNavigationMenuOpen"
		mode="over"
		color="primary"
	>
		<mat-nav-list>
			<asecrmw-nav-list
				listDirection="column"
				buttonStyles="secondary"
				(menuClick)="onNavigationListClick()"
			/>
		</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content class="page-scroll__content">
		<div cdkScrollable>
			<asecrmw-breadcrumbs />
			<router-outlet />
		</div>
		<asecrmw-footer class="page-footer" />
	</mat-sidenav-content>
</mat-sidenav-container>
