import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UpperCasePipe } from '@angular/common';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

/** Breadcrumbs component. */
@Component({
	selector: 'asecrmw-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [BreadcrumbComponent, BreadcrumbItemDirective, UpperCasePipe],
})
export class BreadcrumbsComponent { }
